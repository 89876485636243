<template>
  <div class="form__checkbox-group" :class="{ 'form__checkbox-desc': field.description }">
    <h4 v-if="field.name">{{ field.name }} <sup v-show="field.required">*</sup></h4>
    <p v-if="field.placeholder" v-html="field.placeholder"></p>
    <p v-if="field.required && field.min_checked > 1">Zaznacz co najmniej {{ field.min_checked }} odpowiedzi</p>
    <div class="form__checkbox-options">
      <div v-for="(option, index) in field.options" :key="index">
        <div class="form__field" :class="'form__field--'+field.acf_fc_layout">
          <input 
            type="checkbox" 
            v-model="input"
            :id="id+'-'+index" 
            :value="option.name"  
            @change="$emit('update:modelValue', value)"
            @focus="focusCustom"
            :required="option.required"
            :class="classObject[index]">
          <label :for="id+'-'+index">{{ option.name }} <sup v-show="option.required">*</sup></label>
          <SvgImage name="checkmark"/>
          <p 
            v-if="showCustom(option.name)" 
            @input="handleCustom" 
            contenteditable="true" 
            class="form__custom-field"></p>
        </div>
      </div>
    </div>
    <div class="form__field-description" v-if="field.description" v-html="field.description"></div>
  </div>
</template> 

<script>
  export default { 
    name: 'FieldCheckbox',

    data() {
      return {
        input: [], 
        customInput: ''
      }
    },

    methods: {
      handleCustom(event) {
        this.customInput = event.target.innerText;

        let input = event.target.parentNode.querySelector('input[type="checkbox"]');
        if(input){
          input.dispatchEvent(new Event('change'));
        }
      },

      replaceCustom() {
        let output = [];
        this.input.forEach(option => {
          option = option.toLowerCase();
          if(option == 'inny' || option == 'inna' || option == 'inne'){
            option += ': ';
            option += this.customInput;
          }
          output.push(option);
        });
        return output;
      },

      showCustom(option) {
        option = option.toLowerCase();
        if(option == 'inny' || option == 'inna' || option == 'inne'){
          return true;
        } else {
          return false;
        }
      },

      focusCustom(event){
        let content = event.target.parentNode.querySelector('p');
        if(content){
          if(!event.target.checked){
            event.target.parentNode.querySelector('p').focus();
          }
        }
      }
    },

    computed: {
      id() {
        return 'field-' + this.itemKey + '-' + this.fieldKey;
      },

      value(){
        return {
          name: this.field.name.length ? this.field.name : this.field.options[0].name,
          value: this.replaceCustom().join(', '),
          valid: this.valid,
          action: this.field.action ? this.field.action : false
        }
      },

      valid(){
        let isValid = true;
        if(this.field.required && this.field.min_checked){
          isValid = this.input.length >= this.field.min_checked;
        }
        this.field.options.forEach(option => {
          if(option.required && this.input.indexOf(option.name) < 0)
            isValid = false;
        });
        return isValid; 
      },

      classObject() {
        return this.field.options.map(option => {
          return {
            invalid: (option.required && this.input.indexOf(option.name) < 0) || (this.field.required && this.input.length < this.field.min_checked)
          }
        });
      }
    },

    props: {
      field: Object,
      itemKey: Number,
      fieldKey: Number
    }
  }
</script>

<style lang="scss" scoped>
  
</style>