<template>
  <div v-if="page">
    <MainHeader :header="headerData"/>
    <MainForm :id="pageID" :header="headerData"/>
    <MainFooter/>
  </div> 
  {{ loading}}
  <div v-if="!connection" class="container">
    <div class="error">
      <p>{{ error }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MainHeader from './components/MainHeader.vue'
import MainForm from './components/MainForm.vue'
import MainFooter from './components/MainFooter.vue'

export default {
  name: 'App',

  data() {
    return {
      connection: true,
      error: '',
      loading: '',
      frontpage: 0,
      page: false,
      form: 'Formularz nie został zdefiniowany w Wordpressie'
    }
  },

  components: {
    MainHeader,
    MainForm,
    MainFooter
  }, 

  computed: {
    headerData() {
      return {
        title: this.page.title.rendered,
        description: this.page.content.rendered
      }
    },
    pageID() {
      return this.page.id
    }
  },

  async mounted() {

    await axios
      .get(process.env.VUE_APP_WP_PATH + '/wp-json/brief/frontpage/')
      .then(response => {
        this.frontpage = response.data
      })
      .catch(() => {
        this.connection = false
        this.error = 'Brak połączenia z bazą danych'
      })

    await axios
      .get(process.env.VUE_APP_WP_PATH + '/wp-json/wp/v2/pages')
      .then(response => {
        // FOR SUBDOMAINS
        let subdomain = window.location.host.split('.')[0];
        response.data.forEach(page => {
          if(page.slug == subdomain){
            this.page = page
          }
        });
        
        // FOR WORDPRESS
        // let url = window.location.href;
        // url = url.replace(window.location.origin, process.env.VUE_APP_WP_PATH);
        // response.data.forEach(page => {
        //   if(page.link.indexOf(url) != -1){
        //     this.page = page
        //   }
        // });
        
        document.querySelector('title').innerText = this.page.title.rendered + ' | reprezentuj.com';  
      })
  }
}
</script>

<style lang="scss">
  .error {
    margin:  40px 0 ;
    padding:  20px 40px;
    background: rgba($color-warn, .3);
    color: $color-warn;
  }
</style>