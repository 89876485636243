<template>
  <div class="form__field" :class="'form__field--'+field.acf_fc_layout">
    <textarea 
      type="text"
      v-model="input"
      :id="id" 
      @change="$emit('update:modelValue', value); isTouched=true"
      :placeholder="field.placeholder"
      :required="field.required"
      :class="classObject"></textarea>
    <label :for="id">{{ field.name }} <sup v-show="field.required">*</sup></label>
  </div>
</template>

<script>
  export default { 
    name: 'FieldTextarea',

    data(){
      return {
        input: '',
        isTouched: false
      }
    },

    props: {
      field: Object,
      itemKey: Number,
      fieldKey: Number,
    },

    computed: {
      id() {
        return 'field-' + this.itemKey + '-' + this.fieldKey;
      },

      value(){
        return {
          name: this.field.name,
          value: this.input,
          valid: this.valid
        }
      },

      valid() {
        let isValid = !this.field.required;

        if(this.input.length > 0){
          isValid = this.input.length >= 3;
        } 

        return isValid; 
      },

      classObject() {
        return {
          filled: this.input.length > 0,
          touched: this.isTouched,
          invalid: !this.valid
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  
</style>