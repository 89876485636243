<template>
  <div class="form__field" :class="'form__field--'+field.acf_fc_layout">
    <input 
      :type="field.type"
      v-model="input"
      :id="id" 
      @change="$emit('update:modelValue', value); isTouched=true"
      :placeholder="field.placeholder"
      :required="field.required"
      :class="classObject"> 
    <label :for="id">{{ field.name }} <sup v-show="field.required">*</sup></label> 
  </div>
</template>

<script>
  export default { 
    name: 'FieldText',

    data(){
      return {
        input: '',
        isTouched: false
      }
    },

    props: {
      field: Object,
      itemKey: Number,
      fieldKey: Number,
    },

    computed: {
      id() {
        return 'field-' + this.itemKey + '-' + this.fieldKey;
      },

      value() {
        return {
          name: this.field.name,
          value: this.input,
          valid: this.valid
        }
      },

      valid() {
        let isValid = !this.field.required;

        if(this.input.length > 0){
          if(this.field.type == 'text'){
            isValid = this.input.length >= 3;
          }
          if(this.field.type == 'phone'){
            isValid = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm.test(this.input);
            if(this.input.length < 7) isValid = false;
          }
          if(this.field.type == 'email'){
            isValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.input);
          }
          if(this.field.type == 'url'){
            let url = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?", "i");
            isValid = url.test(this.input);
          }
        }

        return isValid; 
      },

      classObject() {
        return {
          filled: this.input.length > 0,
          touched: this.isTouched,
          invalid: !this.valid
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  
</style>