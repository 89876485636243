<template>
	<main class="main">
		<form 
			v-if="form" 
			name="brief" 
			class="form container" 
			:class="{ 'form--touched': isTouched }" 
			@submit.prevent="submitForm" 
			novalidate="true"> 			
			<div v-if="header.description" class="form__description">
	      <div class="container" v-html="header.description"></div>
	    </div>
			  <!-- <pre>{{ form }}</pre> -->

			<fieldset v-for="(item, item_key) in acf" :key="item_key">
				<h2 v-if="item.heading">{{ item_key+1 }}. {{ item.heading }}</h2>
				<p v-if="item.description" v-html="item.description"></p>
				<div class="form__grid" :class="classObject[item_key]">
					<div v-for="(field, field_key) in item.fields" :key="field_key" class="form__grid-item" :class="{ 'form__grid-item--auto': field.acf_fc_layout=='checkbox' && field.options.length < 10 || field.acf_fc_layout=='radio' && field.options.length < 10, 'form__grid-item--cols': field.acf_fc_layout=='checkbox' && field.options.length >= 10 || field.acf_fc_layout=='radio' && field.options.length >= 10 }">
						<FieldText 
							v-if="field.acf_fc_layout=='text'" 
							:field="field" 
							:itemKey="item_key" 
							:fieldKey="field_key" 
							v-model="form[item_key].fields[field_key]" />
						<FieldTextarea
							v-if="field.acf_fc_layout=='textarea'" 
							:field="field" 
							:itemKey="item_key" 
							:fieldKey="field_key" 
							v-model="form[item_key].fields[field_key]" />
						<FieldCheckbox
							v-if="field.acf_fc_layout=='checkbox'" 
							:field="field" 
							:itemKey="item_key" 
							:fieldKey="field_key"
							v-model="form[item_key].fields[field_key]" />
						<FieldRadio
							v-if="field.acf_fc_layout=='radio'" 
							:field="field" 
							:itemKey="item_key" 
							:fieldKey="field_key"
							v-model="form[item_key].fields[field_key]" />
						<FieldRange
							v-if="field.acf_fc_layout=='range'" 
							:field="field" 
							:itemKey="item_key" 
							:fieldKey="field_key"
							v-model="form[item_key].fields[field_key]" />
						<button 
							v-if="field.acf_fc_layout=='submit'" 
							:disabled="isLoading"
							type="submit"
							class="button">	
							<span v-show="!isLoading">{{ field.name }}</span>
							<span v-show="isLoading">Chwila, moment...</span>
							</button>
					</div>
				</div>				
			</fieldset>
		</form>
	</main> 

	<div v-show="isFormSent" class="popup">
		<div class="popup__content">
			<img v-if="thanks.image" :src="thanks.image" alt="Wysłano formularz - animacja">
			<div class="popup__text">
				<h2>{{ thanks.heading }}</h2>
				<div v-html="thanks.text"></div>
				<a v-if="thanks.button" :href="thanks.button.url" :target="thanks.button.target" class="button">
					<span>{{ thanks.button.title }}</span>
				</a>
				<ul class="social">
					<li>
						<a href="https://www.facebook.com/reprezentuj/" target="_blank"><SvgImage name="facebook"/></a>
					</li>
					<li>
						<a href="https://www.instagram.com/reprezentuj/" target="_blank"><SvgImage name="instagram"/></a>
					</li>
					<li>
						<a href="https://www.youtube.com/user/thereprezentuj" target="_blank"><SvgImage name="youtube"/></a>
					</li>
					<li>
						<a href="https://twitter.com/reprezentujcom" target="_blank"><SvgImage name="twitter"/></a>
					</li>
				</ul>
			</div>
		</div>
	</div>	
</template>

<script>
import axios from 'axios'
import FieldText from '.././components/fields/Text.vue'
import FieldTextarea from '.././components/fields/Textarea.vue'
import FieldCheckbox from '.././components/fields/Checkbox.vue'
import FieldRadio from '.././components/fields/Radio.vue'
import FieldRange from '.././components/fields/Range.vue'

export default { 
  name: 'MainForm',  

  components: {
    FieldText,
    FieldTextarea,
    FieldCheckbox,
    FieldRadio,
    FieldRange
  },

  props: {  
    id: Number,
    header: Object
  },

  data() {
    return {
    	acf: [],
      form: [],
      thanks: [],
      isLoading: false,
      isTouched: false,
      isValid: false,
      isFormSent: false
    }
  },

  methods: {
  	submitForm() {
  		this.isTouched = true;
  		this.isValid = true;

  		this.form.forEach(question => {
  			question.fields.forEach(field => {
  				if(!field.valid) 
  					this.isValid = false;
  			});
  		});

  		if(this.isValid){
  			this.sendForm();

  		} else {
  			let scrollTo = document.querySelectorAll('.invalid');
  			if(scrollTo.length > 0){
  				scrollTo[0].closest('.form__grid-item').scrollIntoView();
  			}

  		}
  	},

  	sendForm() {
  		this.isLoading = true;

  		axios
	      .post(process.env.VUE_APP_WP_PATH + '/wp-json/brief/send/', {
	      	title: this.header.title,
	      	form: this.form
	      })
	      .then(response => {
	      	console.log(response.data);
	        if(response.data.status == 1){
	        	this.isFormSent = true;
	        }
	      })
  		
  	}
  },

  computed: {
  	classObject() {
  		let items = [];
  		this.acf.forEach(item => {
  			let grid = {};
  			grid['form__grid--'+item.layout] = true;

  			if(item.layout === 'auto'){
  				grid['form__grid--'+item.max_cols] = true;

  				if(item.fields.length % parseInt(item.max_cols) !== 0){
  					grid['form__grid--stretch'] = true;
  				}
  			}
  			items.push(grid);
  		});
  		return items;
    }
  },

  async mounted() {
    await axios
      .get(process.env.VUE_APP_WP_PATH + '/wp-json/brief/form/' + this.id)
      .then(response => {
      	this.acf = response.data
        this.acf.forEach(item => {
        	let question = {
	      		heading: item.heading,
	      		fields: []
	      	}
      		for(let i=0; i<item.fields.length; i++){
	      		let field = {
	      			name: item.fields[i].name,
	      			value: '',
	      			valid: !item.fields[i].required
	      		};
	      		if(item.fields[i].acf_fc_layout == 'checkbox'){
	      			item.fields[i].options.forEach(option => {
	      				if(option.required) field.valid = false;
	      			});
	      			if(!item.fields[i].name.length){
	      				field.name = item.fields[i].options[0].name;
	      			}
	      			field.action = item.fields[i].action ? item.fields[i].action : false;
	      		}
	      		if(item.fields[i].acf_fc_layout == 'range'){
	      			field.value = item.fields[i].from + ' - ' + item.fields[i].to + ' ' + item.fields[i].unit
	      		}
	      		if(item.fields[i].acf_fc_layout == 'submit'){
	      			field.action = 'submit';
	      		}
	      		question.fields.push(field);
	      	}
        	this.form.push(question);
        });
      })

    await axios
      .get(process.env.VUE_APP_WP_PATH + '/wp-json/brief/thanks/' + this.id)
      .then(response => {
      	this.thanks = response.data
      })
  }
}
</script>

<style lang="scss" scoped>
	main {
		overflow: hidden;
	}

	h2 {
		text-align: center;
	}

	h2 + p {
		text-align: center;
	}
</style>