<template>
  <svg v-if="name=='logo'" :class="'svg svg--' + name" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.854 118.876" xml:space="preserve" role="presentation">
    <path d="M44.171 76.911H28.659v41.966H0V0h46.784c6.858 0 13.064.774 18.617 2.326 5.552 1.552 10.287 3.836 14.206 6.858 3.918 3.02 6.927 6.765 9.022 11.226 2.093 4.465 3.142 9.636 3.142 15.513 0 4.572-.487 8.644-1.469 12.209-.979 3.565-2.382 6.692-4.204 9.389a30.386 30.386 0 0 1-6.574 7.019c-2.559 1.99-5.388 3.717-8.49 5.185l24.82 47.93v1.223h-30.7L44.171 76.911zM28.659 54.786h18.125c5.443 0 9.539-1.432 12.288-4.288 2.749-2.858 4.125-6.843 4.125-11.96 0-5.115-1.39-9.133-4.167-12.043-2.772-2.913-6.855-4.371-12.246-4.371H28.659v32.662z"/>
  </svg>

  <svg v-if="name=='checkmark'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.4 57.5" xml:space="preserve" role="presentation">
    <path d="M78 8.6 29.5 57.2c-.4.4-1.1.4-1.5 0L.3 29.6c-.4-.4-.4-1.1 0-1.5L7 21.3c.4-.4 1.1-.4 1.5 0l20.1 20.1L69.8.3c.4-.4 1.1-.4 1.5 0L78 7c.5.5.5 1.1 0 1.6z"/>
  </svg>

  <svg v-if="name=='facebook'" width="13" height="25" viewBox="0 0 13 25" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.627 4.151H13V.176C12.59.122 11.182 0 9.542 0 6.12 0 3.776 2.07 3.776 5.874v3.501H0v4.444h3.776V25h4.63V13.82h3.623l.576-4.444h-4.2V6.315c.001-1.285.36-2.164 2.222-2.164Z" fill="#000" fill-rule="nonzero"/>
  </svg>

  <svg v-if="name=='instagram'" width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <g fill="#000" fill-rule="nonzero">
      <path d="M12.5 6A6.5 6.5 0 0 0 6 12.5a6.5 6.5 0 0 0 6.5 6.5 6.5 6.5 0 0 0 6.5-6.5A6.5 6.5 0 0 0 12.5 6Zm0 10.719a4.218 4.218 0 1 1 4.219-4.219 4.217 4.217 0 0 1-4.219 4.219Z"/>
      <path d="M17.654.079c-2.3-.108-7.996-.103-10.299 0C5.333.173 3.548.662 2.11 2.1-.295 4.504.013 7.744.013 12.495c0 4.862-.271 8.027 2.097 10.395 2.413 2.412 5.7 2.096 10.394 2.096 4.817 0 6.48.004 8.183-.656 2.315-.899 4.063-2.969 4.234-6.686.108-2.301.102-7.997 0-10.299C24.715 2.957 22.36.295 17.654.08Zm3.641 21.22c-1.576 1.576-3.762 1.435-8.82 1.435-5.21 0-7.298.077-8.822-1.45-1.755-1.747-1.437-4.553-1.437-8.806 0-5.755-.59-9.9 5.185-10.195 1.327-.047 1.718-.063 5.059-.063l.046.031c5.552 0 9.907-.58 10.168 5.194.06 1.318.073 1.714.073 5.049 0 5.148.097 7.249-1.452 8.805Z"/>
      <circle cx="19.5" cy="5.5" r="1.5"/>
    </g>
  </svg>

  <svg v-if="name=='twitter'" width="25" height="20" viewBox="0 0 25 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.431 4.988A10.145 10.145 0 0 0 25 2.368c-.93.4-1.92.667-2.953.796A5.034 5.034 0 0 0 24.302.375c-.99.582-2.084.992-3.25 1.222A5.172 5.172 0 0 0 17.308 0c-2.837 0-5.122 2.268-5.122 5.047 0 .4.035.785.12 1.15A14.586 14.586 0 0 1 1.74.92a5.03 5.03 0 0 0-.702 2.55c0 1.748.913 3.297 2.277 4.195A5.127 5.127 0 0 1 1 7.042v.055c0 2.453 1.776 4.49 4.106 4.96a5.172 5.172 0 0 1-1.343.166c-.329 0-.66-.019-.97-.087a5.166 5.166 0 0 0 4.788 3.516 10.405 10.405 0 0 1-6.355 2.152c-.42 0-.823-.018-1.226-.069A14.597 14.597 0 0 0 7.862 20c9.045 0 14.94-7.43 14.57-15.012Z" fill="#000" fill-rule="nonzero"/>
  </svg>

  <svg v-if="name=='youtube'" width="28" height="20" viewBox="0 0 28 20" xmlns="http://www.w3.org/2000/svg">
    <path d="m27.38 2.858.036.23c-.339-1.21-1.252-2.144-2.413-2.484l-.025-.006C22.796 0 14.012 0 14.012 0S5.25-.012 3.045.598a3.566 3.566 0 0 0-2.43 2.466l-.006.024c-.815 4.296-.821 9.457.037 14.056l-.037-.233c.339 1.21 1.252 2.144 2.413 2.484l.024.006C5.226 20 14.013 20 14.013 20s8.76 0 10.967-.599a3.566 3.566 0 0 0 2.43-2.466l.007-.024c.37-1.998.583-4.297.583-6.645l-.001-.26.001-.27c0-2.35-.212-4.649-.62-6.878ZM11.21 14.295v-8.58l7.31 4.297-7.31 4.283Z" fill="#000" fill-rule="nonzero"/>
  </svg>
</template>

<script>
  export default { 
    name: 'SvgImage',

    props: {  
      name: String
    }
  }
</script>

<style lang="scss" scoped>
  
</style>