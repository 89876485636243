<template>
  <header class="header" :class="{ 'header--minified': isMinified }">
    <div class="header__hero">
      <a href="https://reprezentuj.com" title="Przejdź do strony reprezentuj.com" target="_blank" class="header__logo">
        <SvgImage name="logo"/>
      </a>
      <nav class="header__menu" v-if="menu">
        <ul>
          <li v-for="(item, index) in menu" :key="index">
            <a :href="item.url" :target="item.target" :class="{ active: item.title == header.title }">
              <span :class="{ 'mobile-only': item.title == 'Employer Branding' }">{{ item.title }}</span>
              <span v-if="item.title == 'Employer Branding'">EB</span>
            </a>
          </li>
        </ul>
      </nav>
      <h1>{{ header.title }}</h1>
      <button type="button" @click="isMinified=true" class="button button--invert"><span>Zaczynamy!</span></button>
    </div>
    <video class="header__video" loop autoplay muted playsinline>
      <source src="https://formularze.reprezentuj.com/wp-content/themes/brief/assets/video/reprezentuj-home.mp4" type="video/mp4">
    </video>
  </header>
</template>

<script>
import axios from 'axios'

export default { 
  name: 'MainHeader',

  data() {
    return {
      menu: [],
      isMinified: false
    }
  },

  props: {  
    header: Object
  },

  mounted() {
    axios
      .get(process.env.VUE_APP_WP_PATH + '/wp-json/brief/menu/')
      .then(response => {
        this.menu = response.data;
      })
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-gray-light;

  &:after {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top:  0;
    left: 0;
    /* background-image: url('../assets/pattern.png');
    background-position: repeat; */
    background: rgba(0,0,0,.3);
    backdrop-filter:  blur(0px);
    transition: backdrop-filter .2s .3s linear;
    content:  '';
  }

  &__hero {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    min-height: 250px;
    transition: height .4s ease;

    .button {
      width: 200px;
      margin-top: 3vh;
      animation: pulse 3s ease infinite;
    }
  }

  &__logo {
    position: absolute;
    z-index: 2;
    display: block;
    width: 30px;
    top: 25px;
    left: 25px;

    @media #{$up640}{
      top:  3vw;
      left: 3vw;
    }
  }

  &__menu {
    position: absolute;
    z-index: 2;
    top:  25px;
    right: 25px;
    font-weight: 700;

    @media #{$up640}{
      top:  3vw;
      right: 3vw;
    }

    ul {
      height: 37px;
      margin:  0;
      padding:  0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color:  #fff;

      a {
        &:hover,
        &.active {
          color:  $color-yellow;
        }
      }
    }

    li {
      margin: 0 0 0 30px;

      @media #{$up640}{
        margin:  0 0 0 50px;
      }
    }

    span {
      &.mobile-only {
        display: none;

        @media #{$up640}{
          display: inline;
        }
      }

      &:nth-child(2){
        @media #{$up640}{
          display: none;
        }
      }
    }
  }

  &__video {
    position: absolute;
    z-index: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: unset;
    max-height: unset;
  }

  h1 {
    margin-top:  0;
    color: #fff;
    transform:  translate(0, 0);
    transition: transform .4s ease;
  }

  &--minified {
    &:after {
      backdrop-filter:  blur(7px);
    }

    .header__hero {
      height: 25vw;
    }

    h1 {
      transform: translate(0, 55px) scale(0.8);

      @media #{$up1220}{
        transform: translate(0, 65px) scale(0.8);
      }
    }

    .button {
      opacity: 0;
      pointer-events: none;
      animation: none;
    }
  }
  @keyframes pulse {
    0% {
      transform:  scale(1.0);
    }
    15% {
      transform:  scale(1.03);
    }
    30% {
      transform:  scale(1.0);
    }
    100% {
      transform:  scale(1.0);
    }
  }
}
</style>
