<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__grid">
        <div class="footer__column">
          <div class="footer__logo-container">
            <a href="https://reprezentuj.com" title="Przejdź do strony reprezentuj.com" target="_blank" class="footer__logo">
              <SvgImage name="logo"/>
            </a>
            <div>
              <h4>reklama i wydarzenia</h4>
              <p>rowery, kawa, fajne strony, mercedesy, rap</p>
            </div>
          </div>
          <ul class="social">
            <li>
              <a href="https://www.facebook.com/reprezentuj/" target="_blank"><SvgImage name="facebook"/></a>
            </li>
            <li>
              <a href="https://www.instagram.com/reprezentuj/" target="_blank"><SvgImage name="instagram"/></a>
            </li>
            <li>
              <a href="https://www.youtube.com/user/thereprezentuj" target="_blank"><SvgImage name="youtube"/></a>
            </li>
            <li>
              <a href="https://twitter.com/reprezentujcom" target="_blank"><SvgImage name="twitter"/></a>
            </li>
          </ul>
        </div>
        <div class="footer__column">
          <div class="footer__info">
            <h4>biuro</h4>
            <p>ul. Wiejska 4, <br>65-609 Zielona Góra</p>
          </div>
          <div class="footer__info">
            <h4>nip i regon</h4>
            <p>929 176 78 62, <br>081 134 53</p>
          </div>
          <div class="footer__info">
            <h4>telefon</h4>
            <p><a href="tel:+48684166161">+48 68 416 61 61</a> <br><a href="tel:+48513922243">+48 513 922 243</a></p>
          </div>
          <div class="footer__info">
            <h4>e-mail</h4>
            <p><a href="mailto:bartek@reprezentuj.com">bartek@reprezentuj.com</a> <br><a href="mailto:biuro@reprezentuj.com">biuro@reprezentuj.com</a></p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
//import axios from 'axios'

export default { 
  name: 'MainFooter',

  data() {
    return {
      html: ''
    }
  },

  mounted() {
    // axios
    //   .get('https://reprezentuj.com/')
    //   .then(response => {
    //     this.html = response.data
    //   })
  }
}
</script>

<style lang="scss">
.footer {
  padding: 50px 0;

  @media #{$up1220}{
    padding: 7vw 0;
  }

  &__grid {
    @media #{$up768}{
      display: flex;
      justify-content: space-between;
    }
  }

  &__logo-container {
    display: flex;
    align-items: center;
    margin-bottom: 50px;

    h4 {
      margin:  0;
    }

    p {
      margin:  0;
    }
  }

  &__column {
    &:nth-child(1){
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 50px;

      @media #{$up768}{
        margin: 0;
        padding-right: 50px;
      }
    }

    &:nth-child(2){
      display: grid;
      grid-gap:  25px;

      @media #{$up640}{
        
        grid-template-columns: 1fr 1fr;
        grid-gap:  50px 100px;
      }

      p {
        margin:  0;
      }
    }
  }


  &__logo {
    position: relative;
    top:  2px;
    width: 60px;
    margin-right: 25px;
  }

  .social {
    position: relative;
    left: -16px;
  }

  h4 {
    margin:  0;
  }

  a {
    text-decoration: none;
  }
}
</style>
