<template>
  <div class="form__checkbox-radio">
    <h4 v-if="field.name">{{ field.name }} <sup v-show="field.required">*</sup></h4>
    <p v-if="field.placeholder" v-html="field.placeholder"></p>
    <div class="form__radio-options">
      <div v-for="(option, index) in field.options" :key="index">
        <div class="form__field" :class="'form__field--'+field.acf_fc_layout">
          <input 
            type="radio" 
            v-model="input"
            :id="id+'-'+index" 
            :name="id"
            :value="option.name" 
            @change="$emit('update:modelValue', value)"
            @focus="focusCustom"
            :required="field.required"
            :class="classObject">
          <label :for="id+'-'+index">{{ option.name }}</label>
          <SvgImage name="checkmark"/>
          <p 
            v-if="showCustom(option.name)" 
            @input="handleCustom" 
            contenteditable="true" 
            class="form__custom-field"></p>
        </div>
      </div>
    </div>
    <div class="form__field-description" v-if="field.description" v-html="field.description"></div>
  </div>
</template>

<script>
  export default { 
    name: 'FieldRadio',

    data() {
      return {
        input: '',
        customInput: ''
      }
    },

    props: {
      field: Object,
      itemKey: Number,
      fieldKey: Number
    },

    methods: {
      handleCustom(event) {
        this.customInput = event.target.innerText;

        let input = event.target.parentNode.querySelector('input[type="radio"]');
        if(input){
          input.dispatchEvent(new Event('change'));
        }
      },

      replaceCustom() {
        let output = this.input;
        if(output == 'inny' || output == 'inna' || output == 'inne'){
          output += ': ';
          output += this.customInput;
        }
        return output;
      },

      showCustom(option) {
        option = option.toLowerCase();
        if(option == 'inny' || option == 'inna' || option == 'inne'){
          return true;
        } else {
          return false;
        }
      },

      focusCustom(event){
        let content = event.target.parentNode.querySelector('p');
        if(content){
          if(!event.target.checked){
            event.target.parentNode.querySelector('p').focus();
          }
        }
      }
    },

    computed: {
      id() {
        return 'field-' + this.itemKey + '-' + this.fieldKey;
      },

      value(){
        return {
          name: this.field.name,
          value: this.replaceCustom(),
          valid: this.valid
        }
      },

      valid(){
        let isValid = true;
        if(this.field.required){
          isValid = this.input.length > 0
        }
        return isValid; 
      },

      classObject() {
        return {
          invalid: !this.valid
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  
</style>