<template>
  <div>
    <div class="form__field" :class="'form__field--'+field.acf_fc_layout">
      <h4 v-if="field.name">{{ field.name }} <sup v-show="field.required">*</sup></h4>
      <vue-slider 
        :interval="invervalValue" 
        :min="minValue" 
        :max="maxValue" 
        :tooltip-formatter="tooltip"
        v-model="input" 
        @change="$emit('update:modelValue', value)" />
    </div>
  </div>
</template>

<script>
  import VueSlider from 'vue-slider-component'
  import 'vue-slider-component/theme/default.css'

  export default { 
    name: 'FieldRange',

    components: {
      VueSlider
    },

    data() {
      return {
        input: [this.field.from, this.field.to],
        tooltip: v => `${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${this.field.unit}`
      }
    },

    props: {
      field: Object,
      itemKey: Number,
      fieldKey: Number
    },

    computed: {
      id() {
        return 'field-' + this.itemKey + '-' + this.fieldKey;
      },

      value(){
        return {
          name: this.field.name,
          value: this.input[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' - ' + this.input[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' ' + this.field.unit,
          valid: true
        }
      },

      minValue() {
        return parseInt(this.field.min)
      },

      maxValue() {
        return parseInt(this.field.max)
      },

      invervalValue() {
        return parseInt(this.field.step);
      }
    }
  }
</script>

<style lang="scss">
  .vue-slider {
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .vue-slider-rail { 
    border-radius: 0;
    background: $color-gray-light;
    height: 10px;
  }

  .vue-slider-process {
    background: $color-yellow;
  }

  .vue-slider-dot-tooltip {
    visibility: visible !important;
  }

  .vue-slider-dot-tooltip-inner {
    border-color: $color-black;
    background: $color-black;
    font-size: inherit;
    border-radius: 0;
    padding:  6px 14px;
  }

  .vue-slider-dot-handle {
    box-shadow: none;
    background: $color-black;
  }
</style>